import React, { useEffect, useRef } from "react"
import styled from "styled-components"

const QuickInfo = props => {
  const radhus = useRef(null)
  const lagenheter = useRef(null)
  const gemensamt = useRef(null)
  const ovrigt = useRef(null)
  
  const infos = [radhus, lagenheter, gemensamt, ovrigt]
  
  useEffect(() => {
    const { hideQuick } = props

    infos.forEach(item => {
      item.current.classList.forEach(itemClass => {
        itemClass === hideQuick ? item.current.classList.add("hide") : item.current.classList.add("visible");
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container className="snabbfakta">
      <h2 className="desktop-heading">Kalcium</h2>
      <h2 className="mobile-heading">Snabbfakta</h2>
      <div className={`infoSection`}>
        <h3></h3>
        <ul>
          <li>Preliminärt tillträde: Enligt överenskommelse</li>
        </ul>
      </div>
      <div ref={lagenheter} className={`infoSection lagenheter`}>
        <h3>Lediga lägenheter</h3>
        <ul>
          <li>2 ROK om 67 kvm</li>
          <li>Läs mer under: <a href="https://cdn.sightline.se/1584a7c4a62afa81/psw/Elinegard/" target="_blank" style={{ color: 'white', }}>HITTA DITT BOENDE</a></li>
          <li>I avgiften ingår uppvärmning, <br/>förbrukning kallvatten och <br/>bostadsrättstillägg.</li>
        </ul>
      </div>
      <div ref={radhus} className={`infoSection radhus`}>
        <h3>Radhus</h3>
        <ul>
          <li>
            Storlek: ca 125 m<sup>2</sup>
          </li>
          <li>Rum: 5</li>
          <li>Avgift: 6527 kr</li>
          <li>I avgiften ingår uppvärmning, <br/>förbrukning kallvatten och <br/>bostadsrättstillägg.</li>
        </ul>
      </div>
      <div ref={gemensamt} className={`infoSection gemensamt`}>
        <h3>Gemensamt</h3>
        <ul>
          <li>Bilparkering: Det finns lediga garageplatser som kan nyttjas från tillträdet.</li>
          <li>
            
          </li>
        </ul>
      </div>
      <div ref={ovrigt} className={`infoSection ovrigt`}>
        <h3>Övrigt</h3>
        <ul>
          <li>
            Gemensam takterrass, cykelverkstad,<br/>
            växthus och övernattningslägenhet.
          </li>
        </ul>
      </div>
    </Container>
  )
}

const Container = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  
  .hide {
    display: none;
  }

  h2 {
    font-family: 'HelveticaNeue';
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 50px;
  }

  h3 {
    margin-bottom: 10px;
    margin-top: 16px;
    font-size: 16px;
    margin-bottom:0;
    font-weight: 100;
    text-transform: uppercase;
    font-family: 'HelveticaNeueRoman';
    
  }

  ul {
    margin: 0;

    li {
      margin: 0;
      list-style: none;
      line-height: 16px;
      padding-top: 2px;
      padding-bottom: 2px;
      font-family: 'HelveticaNeueRoman';
    }
  }

  .infoSection {
  }

  @media only screen and (max-width: 500px) {
    padding: 1rem;
  }

  @media only screen and (max-width: 800px) {
    .desktop-heading{
      display: none;
    }
  }
  @media only screen and (min-width: 801px) {
    .mobile-heading{
      display: none;
    }
  }
`

export default QuickInfo
