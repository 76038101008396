import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import QuickInfo from "./QuickInfo"
import Showings from "./Showings"
import Video from "../video/drone-video.mp4"
import PropTypes from "prop-types"

const Hero = props => {
  const { heroImage, iconImage, hideQuick } = props
  return (
    <HeroContainer className={props.heroClass}>
      <div className={'backgroundImage'}>
        {heroImage ? (
          <Img fluid={heroImage} />
        ) : (
          <video
            autoPlay={true}
            playsInline={true}
            muted={true}
            loop={true}
            src={Video}
          />
        )}
      </div>
      <div href="mailto:ebba.jonsson@svenskfast.se" className="hero-splash-container">
        <div className="hero-splash">
          Endast 2 lägenheter kvar!<br></br>Kontakta vår mäklare för visning<br></br>
          <a href="mailto:ebba.jonsson@svenskfast.se?subject=Kalcium - visning">ebba.jonsson@svenskfast.se</a>
        </div>
      </div>
      <div className="filler filler1"></div>
      <div className="filler filler2">
        
      </div>
      {iconImage && (
        <div className="icon">
          <img src={iconImage} alt="icon"/>
        </div>
      )}
      <QuickInfo hideQuick={hideQuick} />
      <Showings />
    </HeroContainer>
  )
}

const HeroContainer = styled.section`
  display: grid;
  grid-template-columns: 35vw minmax(200px, 50vh) 1fr;
  grid-template-rows: 68px calc(50vh - 34px) 1fr;
  height: 100vh;
  min-height: 700px;
  
  &.default-hero .backgroundImage {
    filter: brightness(65%);
  }
  
  .backgroundImage {
    grid-column: 1 / 4;
    grid-row: 1 / 4;
    
    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
    }

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      filter: brightness(65%);
    }
  }

  .icon {
    grid-column: 2 / 3;
    grid-row: 2;
    z-index: 9;
    padding: 20px;
    display: flex;
    justify-content: center;
    border-top: 1px solid #fff;
    
    img {
      margin-bottom: 0;
    }
    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
      max-width: 300px;
      margin: 0 auto;

      img {
        object-fit: contain !important;
      }
    }
  }

  .snabbfakta {
    grid-column: 1 / 4;
    grid-row: 3;
    z-index: 99;
    padding: 2rem;
    border-top: 1px solid #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .showings {
    grid-column: 3;
    grid-row: 3;
    z-index: 99;
    padding: 2rem;
    width: 100%;
    height: 100%;
  }

  .hero-splash-container{
    z-index: 2;
    grid-column: 3;
    grid-row: 2;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero-splash{
    padding: 2rem;
    color: white;
    border: 1px solid white;
    text-align: center;
    font-size: 24px;
  }

  .hero-splash a{
    display: inline-block;
    margin-top: 1rem;
    color: white;
  }

  .filler1 {
    z-index: 1;
    grid-column: 1;
    grid-row: 2 / 4;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
  }

  .filler2 {
    z-index: 1;
    grid-column: 3;
    grid-row: 2 / 4;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
  }

  @media only screen and (max-width: 1300px) {
    grid-template-rows: 68px calc(33.33vh - (68 / 3)) calc(33.33vh - (68 / 3)) calc(
        33.33vh - (68 / 3)
      );

    .backgroundImage {
      grid-column: 1 / 4;
      grid-row: 1 / 5;
    }

    .filler2 {
      grid-row: 2;
    }
    .snabbfakta {
      border-right: 0;
    }
    .showings {
      grid-column: 2 / 4;
      grid-row: 3 / 5;
      border-top: none;
    }
  }

  @media only screen and (max-width: 1200px) {
    .snabbfakta {
      grid-column: 1;
    }
    .showings {
      border-top: 1px solid #fff;
    }
    .filler2 {
      display: none;
    }

    .hero-splash-container{
      grid-column: 1;
    }

    .hero-splash{
      padding: 1rem;
      font-size: 18px;
    }

    .icon {
      grid-column: 2 / 4;
      border-top: 1px solid #fff;
    }
  }

  @media only screen and (max-height: 1150px), (max-width: 800px) {
    grid-template-rows: 68px calc(35vh - 34px) 1fr;
    grid-template-columns: 35vw 35vh 1fr;
    height: 100%;
    min-height: 740px;
  }

  @media only screen and (min-height: 940px) and (max-width: 800px) {
    grid-template-rows: 150px 100px 100px auto 1fr;

  }

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 100px 100px 100px 300px 1fr;

    .icon {
      grid-column: 1;
      grid-row: 2;
      margin: 0 auto;
      width: 100%;
      height: 100px;
      width: 100px;
      border: 1px solid #fff;
    }

    .hero-splash-container{
      grid-row: 6;
      padding: 1rem 1rem 3rem;
    }

    .hero-splash{
      font-size: 16px;
    }

    .filler2 {
      display: initial;
    }
    
    .backgroundImage {
      grid-column: 1;
      grid-row: 1 / 7;
    }

    .filler1 {
      display: none;
    }

    .filler2 {
      grid-column: 1;
      grid-row: 3;
      position: relative;
      border-top: none;
      border-bottom: none;
      &::before {
        content: "Kalcium";
        width: 100%;
        height: 100%;
        text-align: center;
        display: inline-block;
        font-size: calc(50px + (120 - 50) * ((100vw - 300px) / (1600 - 300)));
        font-weight: 700;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }

    .showings {
      grid-column: 1;
      grid-row: 4;
      border: none;
      text-align: center;
      padding: 2rem 1rem;
    }

    .snabbfakta {
      grid-row: 5;
      justify-content: start;
      border-right: none;
      border: none;
      text-align: center;
      padding: 2rem 1rem;
    }
  }
`

Hero.propTypes = {
  heroClass: PropTypes.string.isRequired,
}

export default Hero
