import React from 'react'
import styled from 'styled-components'

const Showings = () => {
  return (
    <StyledShowings className="showings">
      <div>
        {/* <p className="bigText">24/6</p>
        <p className="address">Fredag 24 juni Kl 13 – 14.30</p>
        <p className="address">Blåsebergavägen 12</p> */}
        <p className="welcome-splash">Precis intill<br/> Kalkbrottet<br/> i Limhamn!</p>
      </div>
    </StyledShowings>
  )
}

const StyledShowings = styled.section`
  .welcome-splash{
    text-align: center;
    font-size: 41px;
    line-height: 46px;
    text-transform: uppercase;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  div {
    color:#fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .bigText {
    font-size: calc(50px + (100 - 50) * ((100vw - 300px) / (1600 - 300)));
    line-height: calc(1.1em + (1.5 - 1.2) * ((100vw - 300px)/(1600 - 300)));
    font-weight: 500;
    font-family: 'HelveticaNeue';
    margin-bottom:0;
    position: relative;

    &::after {
      content: "Visning";
      position: absolute;
      transform: translateY(-70%);
      font-size: 25px;
      right: 0;
      top: -8px;
      font-weight: 500;
      font-size: calc(14px + (30 - 14) * ((100vw - 300px) / (1600 - 300)));
      line-height: calc(1.1em + (1.5 - 1.2) * ((100vw - 300px)/(1600 - 300)));
  }

  }
  .tagline {
    font-weight:300;
    font-size: calc(16px + (25 - 16) * ((100vw - 300px) / (1600 - 300)));
    line-height: calc(1.1em + (1.5 - 1.2) * ((100vw - 300px)/(1600 - 300)));
    position: absolute;
    right: 23%;
    top:-25px;
  }

  .address {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: calc(20px + (25 - 20) * ((100vw - 300px) / (1600 - 300)));
    line-height: calc(1.1em + (1.5 - 1.2) * ((100vw - 300px)/(1600 - 300)));
    padding-bottom: 6px;
    text-align: center;
  }

  @media only screen and (max-width: 1400px) {
    .address {
      font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    }
  }

  @media only screen and (max-width: 650px) {
    .bigText {
      font-size: calc(120px + (200 - 120) * ((100vw - 300px) / (1600 - 300)));
    }
  }
`

export default Showings
